import React from 'react';
import { ProgressBar } from 'primereact/progressbar';
import './Progress.css'; // Ensure styles are in Progress.css or App.css


const Progress = () => {
  return (
    <div className="progress-container">
      <div className="progress-text2">
        WE ARE FITNESS PARTNERS.
      </div>
      <div className="progress-text2">
        WE Believe In TRANSFORMATION.
      </div>
      <div>
        &nsbp
      </div>
      <div className="progress-text">
        BURN <span className="stroke-text">8000 KGS</span> BY 2026
      </div>
      <div className="progress-bar">
        <div className="progress-fill" style={{ width: '50%' }}>
          <span className="progress-text3">50%</span>
        </div>
      </div>
      <div className="progress-subtext">4000 KGS ALREADY BURNED!</div>
    </div>
  );
};

export default Progress;
