import React, { useState, useEffect } from 'react';
import galleryData from '../../data/galleryData';
import './Gallery.css';

const Gallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const length = galleryData.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [length]);

  return (
    <section className="gallery-section">
      <h2 className="gallery-heading">
        <span className="stroke-text2">Gallery</span>
        
        <span className="progress-text2">Explore Our Space</span>
      </h2>
      <div className="gallery">
        <img
          src={galleryData[currentIndex]}
          alt={`Gallery Image ${currentIndex + 1}`}
          className="gallery-image"
        />
      </div>
    </section>
  );
};

export default Gallery;
