import React from "react";
import "./Footer.css";
import wp from "../../assets/whatsapp-logo.jpeg"

const Footer = () => {
  return (
    <>
    <div className="Footer-container" id="about">
    <section className="footer">
        <div className="box-container">
          <div className="box">
            <h3>Address</h3>
            <a href="https://g.co/kgs/4kCRhQM">Raj Gaurav Multipurpose Hall, Ruikar Colony, Signal, Kolhapur, Maharashtra - 416005</a>
            <a href="https://wa.me/919970989133?text=Hi%20there!%20I%20want%20to%20join%20the%20gym%20💪"><img alt="whatsapp" src={wp} height="100" width="150" className="btn"/></a>
          </div>
        <div className="box">
            <h3>Contact Us </h3>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                <i className="fa">
                <a href="tel:+91 9970989133">+91 9970989133 </a></i>  
                <br></br>
                <i className="fa">
                <a href="mailto:f3gymkolhapur@gmail.com">f3gymkolhapur@gmail.com</a></i>
            </div>
        <div className="box">
            <h3>Follow us</h3>
            <link
                      rel="stylesheet"
                      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
                     />
                <div className="hero1">
                <h1>Reach us at</h1>
                 <div className="home__social-icon">
                 <a href="https://www.facebook.com/thef3gym"><i className="fab fa-facebook-f"> Facebook</i></a>
                 <a href="https://www.instagram.com/f3gymkop/?hl=en"><i className="fab fa-instagram"> Instagram</i></a>
                 <a href="https://wa.me/919970989133?text=Hi%20there!%20I%20want%20to%20join%20the%20gym%20💪"><i className="fab fa-whatsapp">Whatsapp</i></a>
                 <a href="https://g.co/kgs/4kCRhQM">
                <i className="fab fa-google">Google Maps</i></a>
               </div>
            </div>
        </div> 
      </div>
    </section>
    <div className="credits">
        <p>© 2024 F3. All Rights Reserved.</p>
      </div>
    </div>
    </>
  );
};

export default Footer;
