import React, { useState, useEffect } from "react";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import Logo from "../../assets/logo.png";
import "./Header.css";
import { Link } from "react-scroll";

const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeMenu = () => {
    setMenuOpened(false);
    console.log('Menu closed');
  };

  return (
    <div className="header" id="header">
      <img src={Logo} alt="Logo" className="logo" style={{ width: "155px", height: "100px" }} />
      
      {isMobile ? (
        <div>
          {menuOpened ? (
            <IoMdClose
              onClick={() => setMenuOpened(false)}
              style={{ width: "1.5rem", height: "1.5rem", color: "white", cursor: "pointer" }}
            />
          ) : (
            <HiOutlineMenuAlt4
              onClick={() => setMenuOpened(true)}
              style={{ width: "1.5rem", height: "1.5rem", color: "white", cursor: "pointer" }}
            />
          )}
          
          {menuOpened && (
            <ul className="header-menu">
              <li>
                <Link onClick={closeMenu} activeClass="active" to="header" spy={true} smooth={true}>
                  Home
                </Link>
              </li>
              <li>
                <Link onClick={closeMenu} to="programs" spy={true} smooth={true}>
                  Programs
                </Link>
              </li>
              <li>
                <Link onClick={closeMenu} to="reasons" spy={true} smooth={true}>
                  Why us
                </Link>
              </li>
              <li>
                <Link onClick={closeMenu} to="testimonials" spy={true} smooth={true}>
                  Testimonials
                </Link>
              </li>
              <li>
                <Link onClick={closeMenu} to="about" spy={true} smooth={true}>
                  About us
                </Link>
              </li>
            </ul>
          )}
        </div>
      ) : (
        <ul className="header-menu">
          <li>
            <Link activeClass="active" to="header" spy={true} smooth={true}>
              Home
            </Link>
          </li>
          <li>
            <Link to="programs" spy={true} smooth={true}>
              Programs
            </Link>
          </li>
          <li>
            <Link to="reasons" spy={true} smooth={true}>
              Why us
            </Link>
          </li>
          <li>
            <Link to="testimonials" spy={true} smooth={true}>
              Testimonials
            </Link>
          </li>
          <li>
            <Link to="about" spy={true} smooth={true}>
              About us
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Header;
