import './App.css';
import Hero from './Components/Hero/Hero';
import Progress from './Components/Progress/Progress';
import Programs from './Components/Programs/Programs';
import Reasons from './Components/Reasons/Reasons';
import Testimonials from './Components/Testimonials/Testimonials';
import Footer from './Components/Footer/Footer';
import Gallery from './Components/Gallery/Gallery';

function App() {
  return (
    
    <div className="App">
      <Hero/>
      <Progress/>
      <Programs/>
      <Gallery/>
      <Reasons/>
      <Testimonials/>
      <Footer/>
    </div>
  );
}

export default App;
