import React from 'react';
import './Hero.css';
import Header from '../Header/Header';
import heroImage from '../../assets/hero_image.png';

const Hero = () => {
  return (
    <div className="hero" id="home">
      <div className="left-h">
        <Header />
        <div className="the-best">
          <span>THE BEST FITNESS CLUB IN THE TOWN</span>
        </div>
        {/* Hero text */}
        <div className="hero-text">
          <div>
            <span className="stroke-text">Functional </span>
            <span>Frame</span>
          </div>
          <div>
            <span>and Fitness</span>
          </div>
          <div>
            <span>Experience the Power of Functional Training</span>
          </div>
        </div>

        {/* Hero buttons */}
        <div className="hero-btns">
        {/* Get Started button redirects to WhatsApp with a pre-typed message */}
        <button
          className="btn"
          onClick={() => window.open('https://wa.me/919970989133?text=Hi%20there!%20I%20want%20to%20join%20the%20gym%20💪', '_blank')}
        >
          Get Started
        </button>

        {/* Learn More button scrolls to the Programs section */}
        <button
          className="btn"
          onClick={() => document.getElementById('programs').scrollIntoView({ behavior: 'smooth' })}
        >
          Learn More
        </button>
        </div>

      </div>

      {/* Right Side: Reserve space for future image */}
      <div className="right-h">
        {/* Add future image here */}
        <div className="future-image-placeholder">
          <img src={heroImage} alt="Hero" className="hero-img" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
