import image1 from "../assets/t-image1.JPG";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "The environment at F3 Gym is unlike any other. The energy, positivity, and camaraderie among the members and trainers make working out an enjoyable and rewarding experience.",
    name: 'Ajay Manwani',
    status : 'ENTREPRENEUR'
  },
  {
    image: image2,
    review: 'Yuvraj sir and the entire team at F3 Gym provide exceptional support and guidance. Their encouragement keeps me motivated and helps me push through every session with confidence.',
    name: 'Aryan Mallhan',
    status: 'COACH'
  },
  {
    image : image3,
    review:'F3 Gym’s functional training programs have taken my fitness to the next level. The tailored workouts challenge me every day, and I can see a huge improvement in my strength and agility.',
    name: 'Rohan Pinjani',
    status: "CUSTOMER"
  }
];
